// https://codesandbox.io/s/react-hooks-counter-demo-dbr34?file=/src/index.js
// https://github.com/d3/d3-time-format
import React from "react"
import PropTypes from "prop-types"

import {ResponsiveBar} from '@nivo/bar'
// import { generateCountriesData, sets } from '@nivo/generators'
import actioncable from 'actioncable';
import {ResponsiveLine} from "@nivo/line";
// import {ResponsiveLine} from '@nivo/line'

const CustomSymbol = ({size, color, borderWidth, borderColor}) => (
    <g>
        <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor}/>
        <circle
            r={size / 5}
            strokeWidth={borderWidth}
            stroke={borderColor}
            fill={color}
            fillOpacity={0.35}
        />
    </g>
)


const styles = {
    // fontFamily: "sans-serif",
    // fontSize: "14px",
    // textAlign: "center",
    height: 400,
    width: "100%"
};


// React mounting component lifecycle is:
//
// - constructor
// - componentWillMount
// - render
// - componentDidMount
// See more here: https://reactjs.org/docs/react-component.html#mounting
class KujiraCoreNotificationsBarChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: 'not joined',
            isConnected: false, // not in use yet
            // data: [{
            //     height: 0,
            //     time: 'unknown',
            //     proposer_address: "loading",
            //     txs_count: 0,
            //     message: "FOO"
            // }]
            items: []
            // data: [
            //     {
            //         "block_number": "block123",
            //         "txs_count": 136,
            //     },
            //     {
            //         "block_number": "block124",
            //         "txs_count": 16,
            //     },
            //     {
            //         "block_number": "block125",
            //         "txs_count": 16,
            //     }]
        }
        this.kujiraCoreChannel = {}

        // use meta-tag value, set by rails by default
        this.cable = actioncable.createConsumer(null) //('ws://localhost:3000/cable')
    }

    componentDidMount() {
        this.setState({message: "please wait..."})


        this.kujiraCoreChannel = this.cable.subscriptions.create('KujiraCoreNotificationsChannel', {
            connected: () => {
                this.setState({message: 'connected ✔ waiting for socket data…'})
            },
            received: (data) => {
                console.log('got ws messages: ' + data.toString());

                this.setState({message: 'last data received from socket at: ' + data.time})
                // this.setState({items: [...this.state.items + data]})

                this.setState(prevState => ({
                    items: [...prevState.items.splice(-14,14), data] // take all last 14 + new one = max 15
                    // items: [...prevState.items, {
                    //     "block_number": "block123",
                    //     "txs_count": 136,
                    // }]
                }))

            }
        })
    }

    render() {
        // const {data} = this.state.items || []
        // const {data} = this.props.items //this.state
        return (
            <div style={styles}>
                {/*<h2>{this.state.message}</h2>*/}

                {/*{this.state.data}*/}
                {/*<p>{this.props.items}</p>*/}
                <ResponsiveBar
                    // data={data}
                    data={this.state.items}
                    // data={this.state.data}
                    // data={this.state.items.map(i => i.txs_count)}
                    // keys={data.map(i => i.address_balances).map(i => i.luna_balance)}
                    // keys={data.map(i => i.value)}
                    // address_balances
                    // indexBy="id"
                    // NOTE: the chart is stacked by the keys
                    // Keys to use to determine each serie.
                    // keys={[ 'txs_count' ]}
                    keys={['txs_count']}
                    // this is the time axis?
                    // Key to use to index the data.
                    indexBy="height"

                    // margin={{top: 8, right: 8, bottom: 8, left: 8}}
                    margin={{top: 20, right: 20, bottom: 60, left: 30}}
                    padding={0.3}
                    // groupMode="stacked"
                    // layout="horizontal"

                    // colors={data.map(c => c.color)}
                    colors={{"scheme": "accent"}}
                    //colorBy="txs_count"
                    // colors={'linear-gradient(to bottom, #fff, #000)'}
                    colorBy={'txs_count'}
                    // enableArea={true}

                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[]}
                    curve="monotoneX"

                    borderColor={{
                        from: 'color',
                        modifiers: [['brighter', '1.6']]
                    }}

                    enableGridX={false}

                    // xScale={{
                    //     type: 'time',
                    //     format: "%Y-%m-%d %H:%M:%S",
                    //     // format: "native",
                    //     useUTC: false,
                    //     // precision: "minute"
                    //     precision: "hour"
                    //     // precision: 'hour'
                    // }}
                    // xFormat="time:%Y-%m-%d %H:%M:%S"
                    yScale={{
                        type: 'linear',
                        min: '0',
                        max: 'auto',
                        stacked: false
                    }}

                    // axisTop={null}
                    // axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 90,
                        //legend: "",
                        legendPosition: 'middle',
                        // legendOffset: 23,
                        // format: '%m-%d %H:%M',
                        // format: '%e. %b %H:%M', // https://github.com/d3/d3-time-format
                        // format: '%e. %b %H:%M', // https://github.com/d3/d3-time-format
                        // format: 'native',
                        // tickValues: "every 6hour",
                        // legend: "time",
                        // legendOffset: -12
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '# of Tx',
                        legendPosition: 'middle',
                        legendOffset: -4,
                        // using d3-format
                        // see https://github.com/d3/d3-format for available formats
                        // format: ".2s"
                        // format: v => `${v}%`
                    }}
                    // enableGridX={true}
                    // enableGridY={false}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                    // legends={[]}

                    // legends={[
                    //     {
                    //         anchor: 'top-left',
                    //         direction: 'column',
                    //         justify: false,
                    //         translateX: 10,
                    //         translateY: 0,
                    //         itemsSpacing: 0,
                    //         itemDirection: 'left-to-right',
                    //         itemWidth: 80,
                    //         itemHeight: 20,
                    //         itemOpacity: 0.75,
                    //         symbolSize: 12,
                    //         symbolShape: 'circle',
                    //         symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    //         effects: [
                    //             {
                    //                 on: 'hover',
                    //                 style: {
                    //                     itemBackground: 'rgba(0, 0, 0, .03)',
                    //                     itemOpacity: 1
                    //                 }
                    //             }
                    //         ]
                    //     }
                    // ]}

                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}

                    // enableDots={false}
                    // dotColor="inherit:darker(0.3)"
                    // dotBorderWidth={2}
                    // dotBorderColor="#e65a14"
                    // enableDotLabel={true}
                    // dotLabel="y"
                    // dotLabelYOffset={-12}
                    //enableArea={true}

                    // enablePointLabel={true}
                    // pointSymbol={CustomSymbol}
                    // pointSize={8}
                    // pointBorderWidth={1}
                    // pointBorderColor={{
                    //     from: 'color',
                    //     modifiers: [['darker', 0.3]],
                    // }}
                    useMesh={false} // X/Y CURSOR to see details, showing point data
                    // debugMesh={true}
                    // enableSlices={'x'}
                    // debugSlices={true}


                />
            </div>
        );
    }
}

KujiraCoreNotificationsBarChart.propTypes = {
    items: PropTypes.array,
    // dataUrl: PropTypes.string
};
export default KujiraCoreNotificationsBarChart
