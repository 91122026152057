import React from 'react';

//import {IconAUST} from "kujira.ui/src/icons/IconAUST";
//import {Select} from "kujira.ui/src/components/Select";
//import {Wallet} from "kujira.ui/src/components/Wallet";
//import {Modal} from "kujira.ui/src/components/Modal";
// mport {NetworkWarning} from "kujira.ui/src/components/Wallet";

const styles = {
    position: 'absolute',
    left: 0,
    top: 0,
    // fontFamily: "sans-serif",
    // fontSize: "14px",
    // textAlign: "center",
    height: "100%",
    width: "100%"
};


class DonateUsk extends React.Component {

    constructor(props) {
        super(props);
        this.state = { message: 'fresh' }
    }

    componentDidMount = () => {
        this.setState({message: "componentDidMount"})
    }

    render() {
        // const listItems = this.state.items.map((d) => <li key={d.tx_hash}><a href={'https://finder.extraterrestrial.money/columbus-4/tx/'+ d.tx_hash}>{d.tx_hash}</a>: {d.tx_amount} {d.height}</li>);
        return (
            <div>
                Donate { this.props.amount } { this.props.denom }
                to { this.props.address }
                <p>
                    <a href='#'>START</a>
                </p>


                <p>
                    {this.state.message}
                </p>
            </div>
        )
    }
}

// DonateUsk.propTypes = {
//     address: PropTypes.string
//     // dataUrl: PropTypes.string
// };
export default DonateUsk

