// https://codesandbox.io/s/react-hooks-counter-demo-dbr34?file=/src/index.js
// https://github.com/d3/d3-time-format
// https://nivo.rocks/treemap/
import React from "react"
import PropTypes from "prop-types"

import { ResponsiveLine } from '@nivo/line'

const styles = {
    // fontFamily: "sans-serif",
    // fontSize: "14px",
    // textAlign: "center",
    height: 400,
    width: "100%"
};

// React mounting component lifecycle is:
//
// - constructor
// - componentWillMount
// - render
// - componentDidMount
// See more here: https://reactjs.org/docs/react-component.html#mounting
class CollateralLineGraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            data: []
        };
    };

    fetchDataWithFetchAPI = () => {
        this.setState({...this.state, isFetching: true});
        fetch(this.props.dataUrl)
            .then(response => response.json())
            .then(result => {
                // console.log("Setting JSON State");
                this.setState({data: result, isFetching: false})
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };

    componentDidMount() {
        console.log("Component mounted");
        this.fetchDataWithFetchAPI();
    };

    render() {
        const {data} = this.state
        return (
            <div style={styles}>
                <ResponsiveLine
                    data={data}
                    // tile={"squarify"}
                    margin={{ top: 20, right: 110, bottom: 90, left: 70 }}
                    //identity="id"
                    //value="value"
                    // valueFormat=".03s"
                    xScale={{ format: "%Y-%m-%dT%H:%M:%S.%L%Z", type: "time" }}
                    xFormat="time:%Y-%m-%dT%H:%M:%S.%L%Z"

                    // xScale={{
                    //     type: 'time',
                    //     // format: "%Y-%m-%d",
                    //     // format: "%Y-%m-%d %H:%M",
                    //     format: '%Y-%m-%dT%H:%M:%S',
                    //     precision: "hour"
                    //     // precision: "day",
                    //     // useUTC: false
                    // }}
                    // xFormat="time:%Y-%m-%d %H:%M"
                    // xFormat="time:%d %b %H:%M"

                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: false,
                        reverse: false
                    }}

                    // curve="monotoneX"

                    // yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    // axisBottom={{
                    //     tickSize: 50,
                    //     tickPadding: 5,
                    //     tickRotation: 0,
                    //     legend: 'transportation',
                    //     legendOffset: 36,
                    //     legendPosition: 'middle'
                    // }}
                    axisBottom={{
                        tickValues: "every 24 hour",
                        tickSize: 2,
                        tickPadding: 8,
                        tickRotation: -45,
                        // format: "%S.%L",
                        format: "%Y-%m-%d %H:%M",
                        legend: "Time",
                        // legendOffset: 36,
                        // legendPosition: "middle"
                    }}

                    axisLeft={{
                        tickSize: 2,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Collateral Value $',
                        legendOffset: -55,
                        legendPosition: 'middle'
                    }}
                    colors={{ scheme: 'nivo' }}
                    enablePoints={false}

                    // leavesOnly={true}
                    // enableParentLabel={false}
                    // innerPadding={0}
                    // outerPadding={0}
                    //color={{scheme: "dark2"}}/
                    //colors={{ scheme: 'yellow_green' }} // https://nivo.rocks/guides/colors/
                    //borderColor={{ from: 'color' }}
                    // width={"100"}
                    // margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    // labelSkipSize={12}
                    // labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.2 ] ] }}
                    // parentLabelTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
                    // borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.1 ] ] }}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
        );
    };
}

CollateralLineGraph.propTypes = {
    greeting: PropTypes.string,
    dataUrl: PropTypes.string
};
export default CollateralLineGraph
