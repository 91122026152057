// https://codesandbox.io/s/react-hooks-counter-demo-dbr34?file=/src/index.js
// https://github.com/d3/d3-time-format
import React from "react"
import PropTypes from "prop-types"

import {ResponsiveBar} from '@nivo/bar'
// import { generateCountriesData, sets } from '@nivo/generators'
import actioncable from 'actioncable';
import {ResponsiveLine} from "@nivo/line";
// import {ResponsiveLine} from '@nivo/line'

const styles = {
    // fontFamily: "sans-serif",
    // fontSize: "14px",
    // textAlign: "center",
    // height: 400,
    // width: "100%"
};


// React mounting component lifecycle is:
//
// - constructor
// - componentWillMount
// - render
// - componentDidMount
// See more here: https://reactjs.org/docs/react-component.html#mounting
class KujiraEventsNotificationsTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: 'not joined',
            isConnected: false, // not in use yet
            // data: [{
            //     height: 0,
            //     time: 'unknown',
            //     proposer_address: "loading",
            //     txs_count: 0,
            //     message: "FOO"
            // }]
            items: []
            // data: [
            //     {
            //         "block_number": "block123",
            //         "txs_count": 136,
            //     },
            //     {
            //         "block_number": "block124",
            //         "txs_count": 16,
            //     },
            //     {
            //         "block_number": "block125",
            //         "txs_count": 16,
            //     }]
        }
        this.kujiraEventChannel = {}

        // use meta-tag value, set by rails by default
        this.cable = actioncable.createConsumer(null) //('ws://localhost:3000/cable')
    }

    componentDidMount() {
        this.setState({message: "please wait..."})


        this.kujiraEventChannel = this.cable.subscriptions.create('KujiraEventNotificationsChannel', {
            connected: () => {
                this.setState({message: 'connected ✔ waiting for socket data…'})
            },
            received: (data) => {
                console.log('got ws messages: ' + data.toString());

                this.setState({message: 'last data received from socket at: ' + data.time})
                // this.setState({items: [...this.state.items + data]})

                this.setState(prevState => ({
                    // items: [...prevState.items.splice(-50,50), data] // take all last 14 + new one = max 15
                    items: [data, ...prevState.items.splice(-50, 50)] // take all last 14 + new one = max 15
                    // items: [...prevState.items, {
                    //     "block_number": "block123",
                    //     "txs_count": 136,
                    // }]
                }))

            }
        })
    }

    render() {
        // const {data} = this.state.items || []
        // const {data} = this.props.items //this.state
        return (
            <div style={styles}>
                {/*<h3>TX Table</h3>*/}
                {/*<h2>{this.state.message}</h2>*/}
                <div className={'table-responsive'}>
                    <table className={'table table-hover'}>
                        <thead>
                        <tr>
                            <th>Block</th>
                            <th>TX</th>
                            {/*<th>Type</th>*/}
                            {/*<th>Sender</th>*/}
                            <th>Contract</th>
                            {/*<th>Protocol</th>*/}
                            <th>Action</th>
                            <th>Params</th>
                        </tr>
                        {/*<tr>{header.map((h, i) => <th key={i}>{h}</th>)}</tr>*/}
                        </thead>
                        <tbody>
                        {Object.keys(this.state.items).map((k, i) => {
                            let data = this.state.items[k];
                            return (
                                <tr key={i}>
                                    {/*<td>{k}</td>*/}
                                    <td className={'font-monospace'}><a
                                        href={`https://finder.kujira.network/kaiyo-1/block/${data.blockHeight}`}
                                        target={"_blank"}>{data.blockHeight}</a></td>
                                    <td className={'font-monospace'}><a
                                        href={`https://finder.kujira.network/kaiyo-1/tx/${data.txId}`}
                                        target={"_blank"}>{data.txIdShort}</a></td>
                                    {/*<td>{data.type}</td>*/}
                                    {/*<td className={'font-monospace'}><a href={`https://finder.kujira.network/kaiyo-1/address/${data.sender}`}*/}
                                    {/*       target={"_blank"}>{data.sender_short}</a></td>*/}
                                    <td className={'font-monospace'}><h4><a
                                        href={`/en/cosmos/kujira/addresses/${data.contract}`}
                                        target={"_blank"}>{data.contract_short}</a></h4></td>
                                    {/*<td>{data.protocol}</td>*/}
                                    <td><h4>{data.action}</h4></td>
                                    <td>
                                        <pre>{JSON.stringify(data.action_params, null, 2)}</pre>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

KujiraEventsNotificationsTable.propTypes = {
    items: PropTypes.array,
    // dataUrl: PropTypes.string
};
export default KujiraEventsNotificationsTable
