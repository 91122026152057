// based on https://dev.to/technicholy/rails-6-actioncable-basics-with-react-4gdl

import React from 'react';
import ReactDOM from 'react-dom';
import actioncable from 'actioncable';
//import TerraCoreNotificationsBarChart from "./TerraCoreNotificationsBarChart";
// import * as Tone from "tone"
// import UnmuteButton from 'unmute'

class KujiraCoreNotificationsApp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: 'not joined',
            isConnected: false, // not in use yet
            data: [{
                height: 0,
                time: 'unknown',
                proposer_address: "loading",
                txs_count: 0,
                message: "FOO"
            }]
        }
        // const synth = new Tone.Synth().toDestination();
        // this.synth = new Tone.Synth().toDestination();
        // Tone.start();
        // console.log('audio is ready');

        this.kujiraCoreChannel = {}

        // use meta-tag value, set by rails by default
        this.cable = actioncable.createConsumer(null) //('ws://localhost:3000/cable')
    }

    componentDidMount = () => {
        this.setState({message: "please wait..."})

        this.kujiraCoreChannel = this.cable.subscriptions.create('KujiraCoreNotificationsChannel', {
            connected: () => {
                this.setState({message: 'connected ✔ waiting for socket data…', proposer_address: "soon..",})
                // this.synth.start()
                // if (Tone.context.state !== 'running') {
                //     Tone.context.resume();
                // }
            },
            received: (data) => {
                console.log('got ws messages: ' + data.toString());

                this.setState({message: 'last data received from socket at: ' + data.time})
                this.setState({data: data})

                // if (Tone.context.state !== 'running') {
                //     Tone.context.resume();
                // }
                // var now = Tone.now()
                // this.synth.triggerAttackRelease("C4", "8n", now)
                // this.synth.triggerAttackRelease("E4", "8n", now + 0.5)
                // this.synth.triggerAttackRelease("G4", "8n", now + 1)
                // if (data.message){
                //     this.setState({message: data.message})
                // }
                // if (data.game){
                //     this.setState({message: 'Game # ' + data.game.id + ' Red user id ' + data.game.red_user_id + ' Blue user id ' + data.game.blue_user_id + '.'})
                // }
            }
            // joined: (name) => {
            //     this.kujiraCoreChannel.perform('joined', {user: name})
            // }
        })

    }
    // handleJoined = (name) => {
    //     this.kujiraCoreChannel.joined(name)
    // }
    render() {
        return (

            <div>
                <h1>Block {this.state.data.height}</h1>
                <p># of Tx: {this.state.data.txs_count}</p>
                {/*<p>Proposer <br/>*/}
                {/*<a href={'https://stake.id/?#/validator/'+ this.state.data.proposer_address} target='_blank'>{this.state.data.proposer_address}</a>*/}
                {/*</p>*/}

                <hr/>
                <div>{this.state.message}</div>
                {/*<button type="button" onClick={() => this.handleJoined(this.state.name)} >Join Game</button>*/}

                {/*//the title which appears on the iOS lock screen*/}
                {/*title : 'Web Audio',*/}
                {/*//force it to start muted, even when the AudioContext is running*/}
                {/*mute : false*/}
                {/*UnmuteButton()*/}
                {/*KujiraCoreNotificationsBarChart list={[this.state.data]}></KujiraCoreNotificationsBarChart>*/}

            </div>

        )
    }
}

KujiraCoreNotificationsApp.propTypes = {
    // greeting: PropTypes.string,
    // dataUrl: PropTypes.string
};
export default KujiraCoreNotificationsApp

